<template>
  <v-card class="mt-3 pt-7">
    <validation-observer>
      <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
        <div class="px-5">
          <h3>Tipo</h3>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="donatorTypeSelected" row>
                <v-radio
                  color="primary-color-1"
                  label="Pessoa física"
                  :value="donatorTypes.PF"
                ></v-radio>
                <v-radio
                  color="primary-color-1"
                  label="Pessoa jurídica"
                  :value="donatorTypes.PJ"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <!-- PF -->
          <div v-if="donatorTypeSelected === donatorTypes.PF">
            <h3>Dados Pessoais</h3>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.donatorPF.name"
                  type="genericText"
                  label="Nome completo"
                  name="firstName"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.email"
                  type="email"
                  label="E-mail"
                  name="email"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.donatorPF.cpf"
                  type="cpf"
                  label="CPF"
                  name="cpf"
                  :isRequired="false"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <phone-field v-model="formDonator.phones" :isRequired="true" />
              </v-col>
            </v-row>
          </div>

          <!-- PJ -->
          <div v-if="donatorTypeSelected === donatorTypes.PJ">
            <h3>Informações da Empresa</h3>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.donatorPJ.fantasyName"
                  type="genericText"
                  label="Nome empresa"
                  name="firstName"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.email"
                  type="email"
                  label="E-mail"
                  name="email"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.donatorPJ.cnpj"
                  type="cnpj"
                  label="CNPJ"
                  name="cnpj"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <phone-field v-model="formDonator.phones" :isRequired="true" />
              </v-col>
            </v-row>
          </div>

          <!-- COMUM -->
          <h3>Endereço</h3>
          <address-form
            :address="formDonator.address"
            @addressForm="updateAddress"
          />

          <!-- PJ -->
          <div v-if="donatorTypeSelected === donatorTypes.PJ">
            <h3>Representante</h3>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.donatorPJ.agent.name"
                  type="genericText"
                  label="Nome completo"
                  name="firstName"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="formDonator.donatorPJ.agent.email"
                  type="email"
                  label="E-mail"
                  name="email"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <phone-field
                  v-model="formDonator.donatorPJ.agent.phones"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider />

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            @click="$router.back()"
            color="#FFF"
            class="mt-1 cancel-button black-3--text"
            :block="$isMobile"
            >Cancelar</v-btn
          >
          <v-btn
            type="submit"
            color="secondary-color-1"
            :disabled="invalid"
            class="ma-0 mt-1 next-step-button white--text"
            :block="$isMobile"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
  import { ValidationObserver } from "vee-validate";

  import AddressForm from "@/modules/core/views/components/AddressForm";
  import Field from "@/modules/core/views/components/Field";
  import PhoneField from "@/modules/core/views/components/PhoneField";

  import { donatorTypes } from "@/modules/donator/enums/donatorTypes.enum";
  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  export default {
    name: "FormDonator",

    components: {
      AddressForm,
      Field,
      PhoneField,
      ValidationObserver
    },

    data: () => ({
      donatorTypeSelected: donatorTypes.PF,
      formDonator: {
        email: null,
        donatorPF: {
          name: null,
          cpf: null
        },
        donatorPJ: {
          fantasyName: null,
          cnpj: null,
          agent: {
            name: null,
            email: null,
            phones: [
              {
                areaCode: null,
                phone: null,
                phoneType: null
              }
            ]
          }
        },
        phones: [
          {
            areaCode: null,
            phone: null,
            phoneType: null
          }
        ],
        address: {
          zipCode: null,
          address: null,
          number: null,
          complement: null,
          neighborhood: null,
          city: null,
          state: {}
        }
      }
    }),

    computed: {
      donatorTypes() {
        return donatorTypes;
      }
    },

    methods: {
      updateAddress(address) {
        this.formDonator = { ...this.formDonator, address: { ...address } };
      },

      prepareDonatorData() {
        const { donatorPF, donatorPJ, phones, ...donator } = this.formDonator;

        if (this.donatorTypeSelected === donatorTypes.PF) {
          return {
            ...donator,
            phones: preparePhonesToSubmit(phones),
            donatorPF: { ...donatorPF }
          };
        }

        return {
          ...donator,
          phones: preparePhonesToSubmit(phones),
          donatorPJ: {
            ...donatorPJ,
            agent: {
              ...donatorPJ.agent,
              phones: preparePhonesToSubmit(donatorPJ.agent.phones)
            }
          }
        };
      },

      submitForm() {
        const data = this.prepareDonatorData();
        this.$emit("submitForm", data);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
