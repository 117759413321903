<template>
  <v-container class="content-page-half">
    <title-header title="Cadastrar Doador" />
    <form-donator @submitForm="handleSubmitForm" />
  </v-container>
</template>

<script>
  import { mapActions } from "vuex";

  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  import FormDonator from "@/modules/donator/views/components/FormDonator";

  export default {
    name: "NewDonator",

    components: {
      FormDonator,
      TitleHeader
    },

    methods: {
      ...mapActions("donator", ["addDonator"]),
      ...mapActions(["toggleSnackbar"]),

      async handleSubmitForm(payload) {
        try {
          const response = await this.addDonator(payload);

          this.toggleSnackbar({
            text: response?.data?.message ?? "Doador criado com sucesso",
            type: "success"
          });

          this.$router.push({ name: "donators" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
